import React, { useState } from "react";
import ProfilePic from "../ProfilePic";
import Sidebar from "../Sidebar";
import ScrollToTop from "../ScrollToTop";
import { Link, useParams } from "react-router-dom";
import { IoIosArrowRoundBack } from "react-icons/io";
import CampaignHeader from "./CampaignHeader";
import "./Campaign.scss";
import CampaignBasicInfo from "./CampaignBasicInfo";
import CampaignPaymentInfo from "./CampaignPaymentInfo";
import CampaignInfluencers from "./CampaignInfluencers";
import useGetCampaignById from "../../utils/useGetCampaignById";
import { ToastContainer } from "react-toastify";

const CampaignDetails = ({ onLogout }) => {
  const params = useParams();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { id } = params;
  const [campaignData, refreshData, isLoading] = useGetCampaignById(
    id,
    page,
    rowsPerPage
  );

  const convertDate = (dateStr) => {
    const date = new Date(dateStr);

    const options = { day: "2-digit", month: "short", year: "numeric" };
    const formattedDate = date.toLocaleDateString("en-GB", options);
    return formattedDate;
  };

  return (
    <div className="home_main">
      <ToastContainer />
      <ScrollToTop />
      <div className="wrapper">
        <Sidebar />
        <div className="main-panel" style={{ backgroundColor: "#f4f8fb" }}>
          <div className="dashboard_main">
            <div className="infl_header_top">
              <div className="dashboard_header infl_head">
                <Link to={"/campaign/all-campaigns"} className="go_back">
                  <IoIosArrowRoundBack className="icon" />
                </Link>
                <div className="logout_box">
                  <ProfilePic onLogout={onLogout} />
                </div>
              </div>
            </div>
            <div className="container">
              <CampaignHeader campaignData={campaignData?.campaign} />
              <CampaignBasicInfo campaignData={campaignData?.campaign} />
              <CampaignPaymentInfo campaignData={campaignData?.campaign} />
              <CampaignInfluencers
                influencers={campaignData?.campaign?.influencers}
                page={page}
                setPage={setPage}
                totalPage={campaignData?.totalPages}
                totalInfluencers={campaignData?.totalInfluencers}
                currentPage={campaignData?.currentPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                refreshData={refreshData}
                isLoading={isLoading}
                campaignName={campaignData?.campaign?.campaignName}
                startDate={convertDate(
                  campaignData?.campaign?.campaignStartDate
                )}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CampaignDetails;
